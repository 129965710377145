import './App.css';
import { Carousel } from 'react-carousel-minimal';
import ImageGallery from './Logo.js';
import React from 'react';
import Title from './wally-logo.png';

function App() {
  const data = [
    {
      image: "https://i.postimg.cc/T3V62MZT/wally1.jpg",
      caption: ``
    },
    {
      image: "https://i.postimg.cc/52VtS3JN/wally12.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/rwNwnryG/wally13.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/1XF9pgYq/wally19.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/zvbs99CZ/wally2.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/W1T2k1md/wally32.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/qRPrMJMM/wally37.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/524Vtk1v/wally41.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/7Lbr3G5Z/wally42.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/nVqbGthB/wally47.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/YStwhDHY/wally7.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/v8PnHCWF/wally10.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/7YdTS7sK/wally11.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/T3b50wBf/wally14.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/XvcGMPVP/wally17.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/xCfk9P81/wally18.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/sx1vhDpF/wally20.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/26GZWKMW/wally3.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/wTy1xMqL/wally31.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/tJf7tQ5s/wally33.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/kMhVdKm7/wally34.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/CL3zR05t/wally35.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/7ZSb7RTL/wally36.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/bv8rRRQ9/wally38.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/tT4gKCv1/wally39.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/sx0G6ZVD/wally4.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/pdZTcnCb/wally40.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/8cFzCXSN/wally43.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/MT3Gq2g9/wally46.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/50bz6V71/wally5.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/5ytFSmWR/wally8.jpg",
      caption: ""
    },
    {
      image: "https://i.postimg.cc/qMz3tQLt/wally9.jpg",
      caption: ""
    }
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{ textAlign: "center" }}>
        {/* <h2>Wally McLean Memorial Pool Tournament</h2> */}
        <img src={Title} alt="Wally McLean Memorial Logo" />
        </div>
        </header>
        <div className="main-body">
        <div style={{
          padding: "20px 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="800px"
            height="600px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={false}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      <br /><br />
      <div id="bracket">
        <iframe title="bracket" src="https://challonge.com/wally_mclean_memorial_2023/module?theme=8228&amp;multiplier=1&amp;match_width_multiplier=1&amp;show_final_results=1&amp;show_standings=0&amp;show_live_status=0&amp;subdomain=" allow="fullscreen" width="90%" height="1500" frameBorder="0" scrolling="auto" allow-transparency="true"></iframe>
    </div>
    <ImageGallery />
    {/* <h1>Memorial Guestbook</h1>
      <GuestBookForm onEntrySubmit={handleEntrySubmit} />
      <GuestBookEntries entries={entries} /> */}
    </div>
    </div>
  );
}

export default App;
