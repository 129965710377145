import React from 'react';
import './ImageGallery.css'; // Import your CSS file for styling

const ImageGallery = () => {
  const imageUrls = [
    'https://cdn.shoplightspeed.com/shops/653311/themes/6800/v/825468/assets/logo.png?20231013204416',
    'https://aactivecoin.com/wp-content/uploads/2023/10/aactivepoollogo.jpg',
    'https://scontent.fyyc2-1.fna.fbcdn.net/v/t39.30808-6/306281360_530308089094372_7496343086402054771_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=5f2048&_nc_ohc=wj5FxndLNgsAX_Hipmj&_nc_ht=scontent.fyyc2-1.fna&oh=00_AfBD8pBRqJllkQDkSzbg4XjfQeaxCwpC2wgcpGHCDtMOeA&oe=655B0C6E',
    'https://scontent.fyyc2-1.fna.fbcdn.net/v/t39.30808-1/309114211_462433425911092_1258631898183952558_n.png?stp=dst-png_p120x120&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=FcSSTB8WMc8AX8Yc5o7&_nc_ht=scontent.fyyc2-1.fna&oh=00_AfDYuNLaNbn5pnDpo_SQloaruFXrNbhs_S39I18yJp5bNQ&oe=655C1E35',
  ];

  return (
    <div className="image-gallery">
      {imageUrls.map((imageUrl, index) => (
        <img key={index} src={imageUrl} alt={`Logo${index + 1}`} />
      ))}
    </div>
  );
};

export default ImageGallery;
